'use strict';

import Swiper from 'swiper/bundle';
var processInclude = require('base/util');
var isMobile = !($(window).width() >= 768);

$(document).ready(function () {
    processInclude(require('./product/detail'));
    processInclude(require('./product/wishlist'));
    // processInclude(require('./components/radioButtons'));
    processInclude(require('./product/mobileDetailsTab'));

    processInclude(require('./mainMobile'));
    
    $('.miniSlider').each(function (index) {
        var $this = $(this);
        var $newClass = `newS${index}`;
        var $newClassP = `newS${index}-promotional`;
        var $parent = $this.parent();
        var launched = $parent.find('.js-promotional-message');
        launched.addClass($newClassP);
        $this.addClass($newClass);

        const newSlide = new Swiper(`.${$newClass}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            pagination: {
                el: ".swiper-pagination",
            },
        });

        const newSlides = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    })

});
