'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/bannerCarouselMobile'));
    processInclude(require('./components/mobileMenu'));
    // processInclude(require('./components/ticketsCollapse'));
    processInclude(require('base/components/footer'));
    // processInclude(require('./components/currencySelector'));
    processInclude(require('./components/countrySelector'));
    processInclude('./components/spinner');
    processInclude(require('./components/deletePayment'));
    processInclude(require('./components/sessionRedirect'));
    processInclude(require('./components/clipboard'));
    processInclude(require('./components/mobileMainCarousel'));
    processInclude(require('./components/newSelect'));
    processInclude(require('./components/profileSearch'));
    processInclude(require('./components/notifications'));

    $(document).on('click', '.js-switcher-toggle', function (e) {
        e.preventDefault();
        $('.header-switcher').toggleClass('open-switcher');
    });

    $(document).on('click', '.js-switcher-closer', function (e) {
        e.preventDefault();
        $('.header-switcher').removeClass('open-switcher');
    });

    $(document).on('click', '.header-switcher', function (e) {
        if ($(e.target).hasClass('header-switcher') && !$(e.target).hasClass('.switcher-item')) {
            $('.header-switcher').removeClass('open-switcher');
        }
    });

    // eslint-disable-next-line consistent-return
    $('body').on('click', '.js-trigger-cart', function (e) {

        if ($('.js-non-power-user').length > 0) {
            $('.js-non-power-user').show();
            $('.js-power-user').hide();
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        if ($('.js-power-user').length > 0) {
            $('.js-power-user').show();
            $('.js-non-power-user').hide();
            e.preventDefault();
            e.stopPropagation();
            return false;
        }

        e.preventDefault();
        var $this = $(this);
        var $parent = $this.closest('.js-cart-feature');
        // dynamically inject ul li for adding product to cart
        var maxQty = 999;
        try {
            var qtys = $parent.find('.js-dynamic-dropdown').attr('data-quantities');
            var multipleEntry = $parent.find('.js-dynamic-dropdown').attr('data-multipleentry');
            var qtysArr = JSON.parse(qtys);
            var multipleTextEntry = $parent.find('.qty-unicorn').attr('data-multipleentrytext');
            $parent.find('.js-multipleEntryText').html(multipleTextEntry.replace('#count', multipleEntry).replace('#br', '<br/>'));
            maxQty = qtysArr[qtysArr.length - 1];

        // eslint-disable-next-line no-empty
        } catch (e) {
        }
        // generate ul li
        var dropdDownHtml = '<ul class="js-dynamically-created">';

        for (var i = 0; i < maxQty; i++) {
            dropdDownHtml += '<li class="' + ((i + 1) === 1 && (multipleEntry === 'null') ? 'active' : '') + ' ' + ((parseInt(i + 1)) === parseInt(multipleEntry) ? 'multipleEntry' : '') + '"><a href="javascript:;" data-value="' + (i + 1) + '">' + (i + 1).toFixed(0) + '</a></li>';
        }
        if (multipleEntry !== 'null') {
            $parent.find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)').addClass('d-none');
        }
        dropdDownHtml += '</ul>';
        $parent.find('.js-dynamically-created').remove();
        $parent.find('.js-dynamic-dropdown').after(dropdDownHtml);
        $parent.find('.qty-unicorn').removeClass('hide');
        $parent.addClass('qtyActive');
        $(document).trigger('addToCartModule:open');
        $(this).parents('.product-detail').find('.cart-quantity.input-number').val(1);
    });

    $('body').on('click', '.deleteQty', function (e) {
        e.preventDefault();
        $(document).trigger('addToCartModule:open');
    });

    $('body').on('mouseup', function (e) {
        var container = $('.new-quantity-dropper-wrapper');
        var $parent = $(e.target).closest('.js-cart-feature');
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $parent.removeClass('qtyActive');
            $parent.find('.js-dynamically-created').remove();
            container.find('li:first-child').addClass('active').siblings().removeClass('active');
        }
    });

    $('body').on('click', '.js-qty-selector ul a', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        var $val = $this.data('value');
        $this.closest('.new-quantity-dropper-wrapper').find('.cart-quantity').val($val);

        try {
            var multipleEntry = $this.closest('.new-quantity-dropper-wrapper').find('.js-dynamic-dropdown').attr('data-multipleentry');
            var multipleTextEntry = $this.closest('.new-quantity-dropper-wrapper').find('.qty-unicorn').attr('data-multipleentrytext');
            var multipleTextBtn = $this.closest('.new-quantity-dropper-wrapper').find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)').attr('data-multipleTextBtn');
            var normalTextBtn = $this.closest('.new-quantity-dropper-wrapper').find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)').attr('data-text');
            if (parseInt(multipleEntry) <= parseInt($val)) {
                $this.closest('.new-quantity-dropper-wrapper').find('.qty-unicorn').addClass('hide');
                $this.closest('.new-quantity-dropper-wrapper').find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)').text(normalTextBtn).removeClass('d-none');
            } else {
                $this.closest('.new-quantity-dropper-wrapper').find('.qty-unicorn').removeClass('hide');
                $this.closest('.new-quantity-dropper-wrapper').find('.js-multipleEntryText').html(multipleTextEntry.replace('#count', parseInt(multipleEntry - $val)).replace('#br', '<br/>'));
                $this.closest('.new-quantity-dropper-wrapper').find('.js-new-add-to-cart-btn:not(.js-add-all-multipleEntry)').text(multipleEntry !== 'null' ? multipleTextBtn.replace('#count', $val) : normalTextBtn).removeClass('d-none');
            }
        // eslint-disable-next-line no-empty
        } catch (e) {
        }

        $parent.addClass('active').siblings().removeClass('active');
    });

    $('body').on('click', '.js-close-blocker', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.closest('.js-main-blocker-popup');

        $parent.hide();
    })
});
