/* eslint-disable new-cap */
'use strict';

module.exports = function () {
    $('.js-trigger-navigation').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('no-scroll');
        $('.site-primary-navigation').addClass('show');
        $('.site-header').addClass('popoutNavi');
        $(document).trigger('mobilemenu:open');
        // gATrack('send', 'event', 'engagement', 'click', 'Menu | Open');
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Menu | Open',
            'value': 'event'
        });
    });

    // Close function for mobile menu
    // =======================================
    $(document).on('click', '.js-close-navigation, .js-new-close-profile', function (e) {
        e.preventDefault();
        $('body').removeClass('no-scroll');
        $('.site-primary-navigation').removeClass('show');
        $('.site-header').removeClass('popoutNavi');
        $('.js-profile-search .js-clear-profile-search').trigger('click');
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Menu | Close',
            'value': 'event'
        });
    });

    $(document).on('click', '.js-new-close-profile-settings', function (e) {
        e.preventDefault();
        $('.js-settings-open-show').hide();
        $('.js-settings-open-hide').show();
    });

    $('.profile-menu-box .menuWithIcons').click(function () {
        var $this = $(this).text();
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Click from Menu | ' + $.trim($this),
            'value': 'event'
        });
    })


    $('.call-to-action a').click(function () {
        var $this = $(this).text();
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Click from Menu | ' + $.trim($this),
            'value': 'event'
        });
    });

    $('.newCart-indicator').click(function () {
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Mobile Cart Button Click',
            'value': 'event'
        });
    });

    $('.section-login .register-btn').click(function () {
        var $this = $(this).text();
        gtag('event', 'click', {
            'event_category': 'engagement',
            'event_label': 'Click from Menu | Register ',
            'value': 'event'
        });
    })

    $('.js-mobileSettings').off('click').on('click', function (e) {
        e.preventDefault();
        var $this = $(this).data('trigger');

        $($this).addClass('active');
        $('html, body').addClass('removeScroll');
        var $iclose = $($this).find('.icon-close');

        $(document).bind('touchend', function (es) {
            var container = $('.dropdownContainer');
            // if the target of the click isn't the container nor a descendant of the container
            if (!container.is(es.target) && container.has(es.target).length === 0) {
                $iclose.trigger('click');
            }
        });
    });

    $('#ticket-sort .icon-close').on('click', function (e) {
        e.preventDefault();
        $(this).closest('#ticket-sort').removeClass('active');
    });

    $('.globalBottomDropdown:not(#ShareBox) ul li a').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        if (!$this.hasClass('active')) {
            var tect = $this.text();
            tect = $.trim(tect);
            $this.addClass('active');
            $this.closest('.globalBottomDropdown').attr('data-selected', tect);
        }
        $this.parent('li').siblings().find('.active').removeClass('active');
    });

    $('#ShareBox .done-btn').click(function () {
        var $cur = $('#ShareBox');
        $cur.removeClass('active');
        $('html, body').removeClass('removeScroll');
    });

    $('.js-scroll-to-products.atHomepage').click(function () {
        $('.site-primary-navigation').removeClass('show');
        $('body').removeClass('no-scroll');
    });

    // if (window.location.href.indexOf('wishlist') > -1) {
    //     $('.mobile-footer-icon').find('.active').removeClass('active');
    //     $('.mobile-footer-icon').find('a[title="Wishlist"], a[title="المفضلة"]').parent().addClass('active');
    // } else if (window.location.href.indexOf('Ticket-ShowActiveTickets') > -1) {
    //     $('.mobile-footer-icon').find('.active').removeClass('active');
    //     $('.mobile-footer-icon').find('a[title="Active Tickets"], a[title="قسائم السحب النشطة"]').parent().addClass('active');
    // }

    var $header = $('.site-header');
    var $flag = false;
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 1) {
            $flag = true;
        } else {
            $flag = false;
        }
        if ($flag) {
            $header.addClass('scrolled-header');
            return false;
            // eslint-disable-next-line no-else-return
        } else {
            $header.removeClass('scrolled-header');
            return false;
        }
    });

    var isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (
                isMobile.Android() ||
                isMobile.BlackBerry() ||
                isMobile.iOS() ||
                isMobile.Opera() ||
                isMobile.Windows()
            );
        }
    };

    // if (isMobile.iOS()) {
    //     $('.use-app-button').attr('href', 'https://app.adjust.com/9m0xal2').addClass('ios-track');
    // } else if (isMobile.Android()) {
    //     $('.use-app-button').attr('href', 'https://app.adjust.com/ys9ka2q').addClass('android-track');
    // }

    $(document).on('click', '.js-open-profile-settings', function (e) {
        e.preventDefault();
        $('.js-settings-open-show').show();
        $('.js-settings-open-hide').hide();
    });
};
